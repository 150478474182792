@import "../variables_custom.scss";

.dms-pref-btn {
    color: black;
    background-color: rgba($dms-color__main, 0.5);
    border: 1px solid $dms-color__main;
  }
  
  .dms-pref-btn:hover {
    background-color: $dms-color__main;
  }