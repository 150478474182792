/*********************************************************************
**********************  ATS CUSTOM STYLESHEET  *********************
*********************************************************************/

/*********************************************************************
*****************************  GENERIC  ******************************
*********************************************************************/
// Colors
$basecolor: rgb(10, 59, 125);

$record-color__main: $basecolor;

/*$aft-login__text__color: $basecolor;*/

$record-dx-grid__selected-row__background-color: rgba($basecolor, 0.2);

$record-logo: url('../images/LogoCustomer.png');
$record-logo-small: url('../images/LogoCustomer_black.png');

//Login
$record-login__background: url('./images/login-background-2.jpg');

$record-login-button__color: #FFF;

$record-selected-text__color: #FFF;