﻿/*********************************************************************
*************************  HEADER STYLESHEET  ************************
*********************************************************************/
@import '../variables_custom.scss';
@import '../../../variables.scss';

.intellic-header__logo--login {
    background: $intellic-logo no-repeat;
    margin: 25px 0;
    height: 50px;
}

.intellic-header__logo__wrapper {
    float: left;
    margin-top: 5px;
    margin-left: 43px;
}

.intellic-header__logo {
    background: $intellic-logo-small no-repeat;
    background-size: contain;
    height: 10px;
    width: 110px;
    margin-top: 3px;
}

.intellic-header__dropdown-menu {
    &.dropdown-menu {
        > li {
            > a,
            > a:focus,
            > a:active,
            > a:hover {
                color: $aft-header__dropdown-menu__item__color;
                font-weight: $aft-header__dropdown-menu__item__font-weight;
            }
        }
        > li:active {
            background-color: rgba($intellic-color__main, 0.5);
        }

        @media (max-width: 767px) {
            left: auto;
            right: 0;
            position: absolute;
            margin: 4px 0 0;
        }
    }
}
.intellic .help-btn{
    background-color: rgba($intellic-color__main, 0.7)!important;
}
  
.intellic  .help-btn:hover{
    color: #3e3f3a !important
}