@import "../variables_custom.scss";

.item-selected.record {
  background-color: rgba($record-color__main, 0.5);
  cursor: pointer;
}

.nav-item.record:not(.item-selected):hover,
.item-active.record {
  background-color: rgba($record-color__main, 0.5);
  cursor: pointer;
}

::ng-deep .mat-menu-panel .menu-item.record:hover {
    background-color: rgba($record-color__main, 0.5) !important;
}
