@import "../../assets/styles/customers/fleet.wortach.com/main.scss";
@import "../../assets/styles/customers/actiafleet.satelicom.com/main.scss";
@import "../../assets/styles/customers/chrono.fleetcontrol.it/main.scss";
@import "../../assets/styles/customers/chronofleet.siaksolution.it/main.scss";
@import "../../assets/styles/customers/fleetmgmt.intellic.com/main.scss";
@import "../../assets/styles/customers/sctacho.recorditalia.net/main.scss";
@import "../../assets/styles/customers/web.dms.srl/main.scss";
@import "../../assets/styles/customers/flotte.ddrspa.com/main.scss";
@import "../../assets/styles/variables.scss";

@media only screen and (min-height: 960px){

    .mat-menu-panel {
        background-color: transparent!important;
        box-shadow: none!important;
        position: absolute;
        z-index: 5;
        left: 0px;
        top: calc(50% + 50px);
        margin-top: -165px;
        overflow: visible!important;
        height: 380px;
        .menu-item{
            color: #333;
            padding: 8px 5px;
            font-size: 13px;
            text-transform: uppercase;
            cursor: pointer;
        }
        .menu-item:hover{
            background-color: rgba($aft-color__main, 0.5) !important;
        }
    }
    

}
.mat-menu-panel {
    background-color: transparent!important;
    box-shadow: none!important;
    position: absolute;
    z-index: 5;
    overflow: visible!important;
    .menu-item{
        color: #333;
        padding: 8px 5px;
        font-size: 13px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .menu-item:hover{
        background-color: rgba($aft-color__main, 0.5) !important;
    }
}

.mat-menu-content {
    padding: 0 !important;
    background: white !important;
    border-radius: 0 !important;
    min-height: unset !important;
    box-shadow: 0px 2px 4px -5px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 15%) !important;
   
    &:empty{
        display: none;
    }
}

.cdk-overlay-connected-position-bounding-box {
    margin-left: 70px;
    margin-top: -64px;
}

.mat-elevation-z4 {
    box-shadow: 0px 2px 4px -5px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 15%);
}