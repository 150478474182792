@import "../variables_custom.scss";

.item-selected.dierre {
  background-color: rgba($dierre-color__main, 0.5);
  cursor: pointer;
}

.nav-item.dierre:not(.item-selected):hover,
.item-active.dierre {
  background-color: rgba($dierre-color__main, 0.5);
  cursor: pointer;
}

::ng-deep .mat-menu-panel .menu-item.dierre:hover {
    background-color: rgba($dierre-color__main, 0.5) !important;
}
