@import "../variables_custom.scss";

::ng-deep #ngwizard.dms>ul>li>a {
  border-radius: 0% !important;
  color: black !important;
  background: rgba($dms-color__main, 0.5) !important;
}


#applyBtn.dms,
::ng-deep #ngwizard.dms>div.btn-toolbar>div.btn-group>button {
  background-color: rgba($dms-color__main, 0.3);
  border: 2px solid $dms-color__main;
}

#applyBtn.dms:hover,
::ng-deep #ngwizard.dms>div.btn-toolbar>div.btn-group>button:hover {
  background-color: rgba($dms-color__main, 0.7);
}

/* Customers Tree Overrides */

::ng-deep .dms-main-bgcolor {
  background-color: $dms-color__main;
  span {
    color: white;
  }
}

::ng-deep .dms .dx-treeview-item:hover {
  background-color: rgba($dms-color__main, 0.2) !important;
}

::ng-deep .dms .dx-treeview-item-without-checkbox.dx-state-focused>.dx-treeview-item {
  background-color: $dms-color__main !important;
}

::ng-deep .dms .dx-treeview-item-without-checkbox.dx-state-focused>.dx-treeview-item span{
  color:white!important;
}

::ng-deep .dms .dx-list-item:hover {
  background-color: rgba($dms-color__main, 0.2) !important;
}
::ng-deep .dms .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
  background-color: rgba($dms-color__main, 0.2) !important;
  color: #333;
}

.dms .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.dms .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date{
    box-shadow: inset 0 0 0 1px #bebebe, inset 0 0 0 1000px rgba($dms-color__main, 0.6);
}
