/*********************************************************************
**********************  ATS CUSTOM STYLESHEET  *********************
*********************************************************************/

/*********************************************************************
*****************************  GENERIC  ******************************
*********************************************************************/
// Colors
$basecolor: #0056a8;

$siak-color__main: $basecolor;

/*$aft-login__text__color: $basecolor;*/

$siak-dx-grid__selected-row__background-color: rgba($basecolor, 0.2);


$siak-logo: url('../images/LogoCustomer.png');
$siak-logo-small: url('../images/LogoCustomer_black.png');

//Login
$siak-login__background: url('./images/login-background-2.jpg');

$siak-login-button__color: #FFF;

$siak-selected-text__color: #FFF;