﻿/*********************************************************************
*************************  HEADER STYLESHEET  ************************
*********************************************************************/
@import '../variables_custom.scss';
@import '../../../variables.scss';

.record-header__logo--login {
    background: $record-logo no-repeat;
    height: 70px;
}

.record-header__logo__wrapper {
    margin-top: 5px;
    margin-left: 43px;
}

.record-header__logo {
    background: $record-logo-small no-repeat;
    background-size: contain;
    height: 32px;
    width: 105px;
    margin-top: -8px;
}

.record-header__dropdown-menu {
    &.dropdown-menu {
        > li {
            > a,
            > a:focus,
            > a:active,
            > a:hover {
                color: $aft-header__dropdown-menu__item__color;
                font-weight: $aft-header__dropdown-menu__item__font-weight;
            }
        }
        > li:active {
            background-color: rgba($record-color__main, 0.5);
        }

        @media (max-width: 767px) {
            left: auto;
            right: 0;
            position: absolute;
            margin: 4px 0 0;
        }
    }
}
.record .help-btn{
    background-color: rgba($record-color__main, 0.7)!important;
}
  
.record  .help-btn:hover{
    color: #3e3f3a !important
}