@import "../variables_custom.scss";

.record-pref-btn {
    color: black;
    background-color: rgba($record-color__main, 0.5);
    border: 1px solid $record-color__main;
  }
  
  .record-pref-btn:hover {
    background-color: $record-color__main;
  }