.leaflet-contextmenu {
    display: none;
    box-shadow: 0 1px 7px rgba(0,0,0,0.4);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 4px 0;
    background-color: #fff;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.leaflet-contextmenu a.leaflet-contextmenu-item {
    display: block;
    color: #222;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
    padding: 0 12px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    cursor: default;
    outline: none;
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled {
    opacity: 0.5;
}

.leaflet-contextmenu a.leaflet-contextmenu-item.over {
    background-color: #f4f4f4;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

.leaflet-contextmenu a.leaflet-contextmenu-item-disabled.over {
    background-color: inherit;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.leaflet-contextmenu-icon {
    margin: 2px 8px 0 0;
    width: 16px;
    height: 16px;
    float: left;
    border: 0;
}

.leaflet-contextmenu-separator {
    border-bottom: 1px solid #ccc;
    margin: 5px 0;
}

.leaflet-routing-container{
    width: 400px;
    padding: 5px;
    input{
        padding: 5px;
        font-size: 14px;
        border-radius: 3px;
    }
    .leaflet-routing-remove-waypoint{
        margin: -10px;
    }
}

.aft-map__icon-label {
    cursor: default;
    display: inline-block;
    position: relative;
    top: -3px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    vertical-align: top;
    color: white;
    padding-left: 20px;
    padding-right: 10px;
    margin-left: 9px;
    border: none;
    background-image: -webkit-radial-gradient(0px 67%, 15px, transparent 0, transparent 15px, transparent 15px, rgba(82, 84, 99, 0.85) 16px);
    background-image: -o-radial-gradient(0px 67%, 15px, transparent 0, transparent 15px, transparent 15px, rgba(82, 84, 99, 0.85) 16px);
    background-image: radial-gradient(12px at 0px 67%, transparent 0, transparent 12px, transparent 12px, rgba(82, 84, 99, 0.85) 13px);
    border-radius: 0 10px 10px 0;
    white-space: nowrap;
}

.overflow-hidden{
    overflow: hidden;
}

.leaflet-routing-container-hide{
    display: none;
}

.leaflet-control-attribution svg {
    display: none !important;
}

.leaflet-interactive{
    cursor: crosshair!important;
}

.leaflet-control > .leaflet-interactive{
    cursor: pointer!important;
}