@import "../variables_custom.scss";

table.satelicom td.active{
    background-color: $satelicom-color__main;
    span > p {
        color: black !important;
    }
}

.satelicom.fa-sign-out{
    background-color: $satelicom-color__main;
    color: black;
}

.activeMapButton-satelicom{
    background-color: $satelicom-color__main!important;
}

.satelicom-map-totalJourneys-table {
    width: 100%;
    height: 80px;
    box-shadow: 0px 0px 2px rgba($satelicom-color__main, 0.7);
    border: 2px solid rgba($satelicom-color__main, 0.7);
    font-size: 12px;
    tr > td {
        i {
            color: $satelicom-color__main;
        }
    }
}