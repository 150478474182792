@import "../../assets/styles/customers/fleet.wortach.com/main.scss";
@import "../../assets/styles/customers/actiafleet.satelicom.com/main.scss";
@import "../../assets/styles/customers/chrono.fleetcontrol.it/main.scss";
@import "../../assets/styles/customers/chronofleet.siaksolution.it/main.scss";
@import "../../assets/styles/customers/fleetmgmt.intellic.com/main.scss";
@import "../../assets/styles/customers/sctacho.recorditalia.net/main.scss";
@import "../../assets/styles/customers/web.dms.srl/main.scss";
@import "../../assets/styles/customers/flotte.ddrspa.com/main.scss";
@import "../../assets/styles/variables.scss";

.w-100{
    width: 100px;
}

.d-flex{
    display: flex;
}

.pull-right{
    margin-left: auto;
    order: 2;
}

.user-header {
    .dropdown-menu{
        border: 0;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        top: 10px !important;
        padding: 0 !important;
        li{
            padding: 12px 15px;
        }
    }
}

.text-danger {
    color: #F04124 !important;
}

.text-danger strong {
    color: #F04124 !important;
}

.dx-context-menu .dx-submenu {
    background-color: #fff;
    border: 1px solid #fff;
    -webkit-box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
    box-shadow: 0 1px 0px rgb(195 195 195 / 15%);
}

.aft-panel-dashboard {
    background: #FFF;
    -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    border: none;
    border-radius: 1px;
}

.aft-panel-dashboard .panel-heading {
    font-size: 15px;
    background-color: #FFF;
    padding: 4px 15px;
    color: #333333;
    border-color: #ddd;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
}

.btn-square-xs {
    width: $btn-square-xs__width;
    height: $btn-square-xs__height;
    color: #3e3f3a !important;
    background-color: #e7e7e7 !important;
    border-color: #ccc !important;
    border-radius: 0 !important;
    padding: 5px 8px !important;
    font-size: 12px !important;
}

.btn-square-md {
  width: $btn-square-md__width;
  height: $btn-square-md__height;
  color: #3e3f3a !important;
  background-color: #e7e7e7 !important;
  border-color: #ccc !important;
  border-radius: 0 !important;
  padding: 8px 11px !important;
  font-size:  1rem !important;
}

.dx-datagrid .dx-link-icon {
    color: #6c6c6c !important;
}

.btn:focus{
    box-shadow: none !important;
}

.dx-datagrid .dx-row > td {
    padding: 4px 7px !important;
    font-size: 12px !important;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link{
    font-size: 15px;
}

.panel{
    padding: 0 !important;
}

.panel-body{
    padding: 0 25px !important;
}

.ml-5{
    margin-left: 5px;
}

.dx-list-item-content {
    padding: 6px !important;
    font-size: 12px;
}

.dx-list-item {
    border: none !important;
}

a:focus-visible{
    outline-offset: unset !important;
    outline: none !important;
}

.dx-widget .dx-collection{
    display: flex !important;
}
.dx-radiobutton {
    margin-right: 20px !important;
}

.mat-progress-bar-fill::after {
    background-color: #009512;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
    background-color: #ffffff !important;
}

table {
    font-size: 12px;
}

small{
    font-size: 80% !important;
}

.ckeck-icon{
    color: green;
}

.aft-invalid {
    border: 1px solid rgba(217, 83, 79, 0.4);
}

.aft-whitespace-prewrap {
    white-space: pre-wrap !important;
}

.cell-highlight{
    background-color: red !important;
}

.aft-json{
    padding:0;
    font-size:inherit;
    background-color:inherit;
    border:0;
}
.aft-vertical-align-top {
    vertical-align: top !important;
}

.aft-padding-top-10 {
    padding-top: 10px !important;
}

.text-warning {
  color: #a47e3c !important;
}

.text-success {
  color: #468847 !important;
}

strong , small, span, pre{
    color: #333;
}

.aft-margin-3{
    margin: 3px;
}

.no-items-selected{
    small{
        font-weight: 400 !important;
        font-size: 11px !important;
    }
    fa-icon{
        color: #D9534F;
    }
}

.h-100{
    height: 100%;
}

.h-500{
    height: 500px;
}

.mat-expansion-panel {
    background: white;
    color: #333;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    .mat-expansion-panel-header-title {
        color: #333;
        font-weight: 500;
    }
    .mat-expansion-panel-header-description, .mat-expansion-indicator::after{
        color: #333;
    }
}

.mat-slide-toggle-bar {
    background-color: rgb(218 218 218 / 50%);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $aft-color__main;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(189, 210, 72, 0.3);
}

.hidden{
    display: none;
}
.aft-functionality__name { font-size: 12px; }

.aft-functionality { font-weight: bold; border: 1px solid lightgray; margin-bottom: 5px; padding: 5px; padding-bottom: 10px; border-radius: 5px; }

.aft-functionality__write-container { display: inline; }

.aft-functionality__description { font-size: 11px; font-weight: normal; width: 100%; margin-top: 5px; }

.aft-feature__header{
    padding: 0 0 10px 3px;
}

.functionality_btn {
   /* width: 80px; */
    height: 25px;
    padding: 2px 15px;
    border: 1px solid #DADCE0;
    color: #8c9196;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    span{
        color: #8c9196;
    }
}

.rw_btn{
    min-width: 100px !important;
}

.functionality_btn.active {
    border: 1px solid #d1df80;
    color: white;
    background-color: $aft-color__main;
    span{
        color: white;
    }
}

.aft-functionality__switch{
    margin-top: 15px !important;
    margin-right: 15px !important;
}

.dx-popup-content{
    padding: 0 !important;
    width: auto;
}

.activeMapButton-aft{
    background-color: $aft-color__main!important;
}

.aft-json{
    padding:0;
    font-size:inherit;
    background-color:inherit;
    border:0;
}
.aft-vertical-align-top {
    vertical-align: top !important;
}

.aft-padding-top-10 {
    padding-top: 10px !important;
}

.aft-routing__icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    text-align: center;
    text-shadow: 0 0 3px white;
    color: #9933CC;
}

.aft-routing__icon_blue {
    font-size: 18px;
    width: 18px;
    height: 18px;
    text-align: center;
    text-shadow: 0 0 3px white;
    color: #0d47a1;
}
.aft-ok__cluster { fill: #00C851; stroke: #fff; background: #00C851; border-color: #fff; }

.aft-ko__cluster { fill: #7E7E87; stroke: #fff; background: #7E7E87; border-color: #fff; }
.aft-markercluster-pie g.arc { fill-opacity: 0.5; }

.aft-markercluster-pie__label { font-size: 12px; font-weight: bold; }

.container-fluid{
    margin: 2px 0 !important;
}

.map .easy-button-container{
    display: flex;
    button:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius : 0;
    }
    button:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius : 0;
    }
    button, button:hover{
        border-bottom: 0;
        border-right: 1px solid #ccc;
    }
}

.leaflet-bar{
    display: none;
}

.leaflet-bar.easy-button-container{
    display: flex;
}

.leaflet-bar.leaflet-control-zoom{
    display: block;
}

.aft-text-align-center{
    text-align: center;
}
.aft-routing__icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    text-align: center;
    text-shadow: 0 0 3px white;
    color: #9933CC;
}

.aft-routing__icon_blue {
    font-size: 18px;
    width: 18px;
    height: 18px;
    text-align: center;
    text-shadow: 0 0 3px white;
    color: #0d47a1;
}

.aft-routing__keyicon {
    font-size: 32px;
    width: 24px;
    height: 32px;
    text-align: center;
    text-shadow: 0 0 3px black;
    color: #4285F4;
}

.aft-map__icon-journeylabel {
    font-size: 10px;
    color: white;
    position: relative;
    bottom: 30px;
}

.aft-map-routing-pushpin {
    text-align: center;
    font-size: 12px;
    width: 20px;
    height: 30px;
    vertical-align: middle;
    margin-right: 5px;
    display: inline-block;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #eee;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #eee transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .aft-main-color{
    color: $aft-color__main;
  }

  .leaflet-control-container > div:nth-child(2){
    right: initial!important;
    left: 11px!important;
    top: 45px!important;
  }

  .leaflet-routing-container {
    display: block;
    width: 450px!important;
    td,h2,h3{
        color: #222;
    }
  }

  .leaflet-routing-alt {

    max-height: calc(100vh - 450px)!important;
  }


  /*SIGNALS BARS*/
  .aft-signalbars {
    display: inline-block;
    height: 20px;
    width: 30px;
}

.aft-signalbars-bar {
    width: 20%;
    margin-left: -1px;
    min-height: 20%;
    display: inline-block;
    background-color: $aft-text-success__color;
    border: thin solid $aft-text-success__darkcolor;
}

.aft-signalbars-1bar {
    height: 20%;
}

.aft-signalbars-2bar {
    height: 40%;
}

.aft-signalbars-3bar {
    height: 60%;
}

.aft-signalbars-4bar {
    height: 80%;
}

.aft-signalbars-5bar {
    height: 99%;
}


.aft-signalbars__4 .aft-signalbars-5bar,
.aft-signalbars__3 .aft-signalbars-5bar,
.aft-signalbars__3 .aft-signalbars-4bar,
.aft-signalbars__1 .aft-signalbars-bar:not(.aft-signalbars-1bar),
.aft-signalbars__2 .aft-signalbars-bar:not(.aft-signalbars-1bar):not(.aft-signalbars-2bar),
.aft-signalbars__ .aft-signalbars-bar {
    background-color: #fafafa;
    border: thin solid #ccc;
}

.aft-signalbars-nosignal {
position: relative;
font-weight: bold;
color: red;
height: 6px;
}

#popupGpsPosition{
    display: flex;
}

.dx-progressbar-container, .dx-progressbar-animating-container {
    height: 20px;
}
.aft-text-danger__color { color: #ff4444; }

.aft-padding-right-5{
    padding-right: 5px;
}

.af-progress-bar-transparent .dx-progressbar-range {
    position: relative;
    border: 0;
    background-color: transparent;
    font-size: 10px;
    text-align: initial;
}

.af-progress-bar-green .dx-progressbar-range {
    position: relative;
    border: 1px solid #00C851;
    background-color: #00C851;
}

.af-progress-bar-orange .dx-progressbar-range {
    position: relative;
    border: 1px solid #F5A147;
    background-color: #F5A147;
}

.af-progress-bar-red .dx-progressbar-range {
    position: relative;
    border: 1px solid #C62825;
    background-color: #C62825;
}


    #realtimeDriver-container tr.dx-row.dx-data-row > td {
        vertical-align: middle;
    }

    #realtimeDriver-container .af-text-orange {
        color: #F5A147;
    }

    #realtimeDriver-container .af-text-red {
        color: #C62825;
    }

.loader{
    margin-top: 50px;
    text-align: center;
}

.aft-map-totalJourneys {
    font-size: 14px;
    color: #333;
}

.aft-map-totalJourneys-table {
    width: 100%;
    height: 80px;
    //box-shadow: 0px 0px 2px #D1DF7F;
    //border: 2px solid #D1DF7F;
    font-size: 12px;
}

.aft-map-datebox {
    position: absolute;
    bottom: 130px;
    left: 10px;
    z-index: 2000;
}

.aft-mapjourney-chart {
    background-color: white;
    height: 200px;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 0;
    z-index: 2002;
}

.dx-text-danger {
    color: #f04124 !important;
}

.dx-text-warning {
    color: #e99002 !important;
}

.dx-text-success {
    color: #358753 !important;
}

.dx-fileuploader-button {
    width: 150px;
    display: block;
    float: left;
    margin-right: 4px;
}

.dx-fileuploader-input-wrapper {
    padding: 0;
}

.dx-fileuploader-dragover .dx-fileuploader-input-container {
    border: none;
    padding: 0;
}

dx-fileuploader-wrapper {
    margin-top: -2px;
    overflow: visible !important;
}

.dx-fileuploader-atf-border {
    display: block;
    border: 2px dashed #959595;
    width: 100%;
}

.dx-fileuploader-input-label {
    color: #959595;
}

.dx-fileuploader-dragover .dx-fileuploader-input-label {
    color: #0a3b7d;
}

.dx-fileuploader-dragover .dx-fileuploader-wrapper {
    background: #0a3b7d;
}

.dx-fileuploader-wrapper {
    padding: 2px !important;
}

.aft-text-align-right{
    text-align: right;
}

.fa-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%;
}

.aft-disabled__color {
    color: darkgray !important;
}

.hidden{
    display: none !important;
}

.aft-map__icon-container {
    position: absolute;
    height: 32px;
    color: $aft-textmain__color;
    cursor: pointer;
    z-index: 5;
    text-align: center;
}

.aft-map__icon-line {
    background-color: #848484;
    position: relative;
    top: -35px;
    left: 11px;
    height: 1px;
    width: 38px;
    transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
}
.aft-height-100-perc {
    height: 100%;
}
.aft .dx-treeview-node .aft-main-bgcolor {
    background-color: $aft-color__main;
 }
 .aft .dx-treeview-node .aft-main-bgcolor:hover {
     background-color: $aft-color__main;
  }

.mat-drawer-inner-container{
    overflow: hidden !important;
}

.mat-expansion-panel {
    background: transparent !important;
    color: #333 !important;
}

.aft-btn__active {
    background-color: $aft-color__main !important;
    color: $aft-selected-text__color !important;
}

.cc-message{

    text-align: center;
    color: white;
    a{
        color: #333 !important;
        text-decoration: none;
    }
}
.cc-compliance{
    a:hover{
        background-color: white !important;
    }
}

.dx-datagrid .dx-row > td.dx-group-cell{
    font-size: 16px !important;
    padding-bottom: 7px !important;
    color: black;
}

.dx-datagrid .dx-row > td.dx-command-expand div{
    font-size: 24px !important;
    padding-top: 4px !important;
    color: black;
}

.dx-freespace-row{
    display: none;
}

.left-align {
    text-align: left !important;
  }

.homepage-powerbi-container powerbi-report div{
    height: 100%;
}

.powerbi-report-container{
    height:800px;
}

.powerbi-report-container powerbi-report div{
    height: 100%;
}

.dx-fileuploader-upload-button{
    display: none !important;
}

.dx-list-item:hover {
    background-color: $aft-color__main !important;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
    background-color: $aft-color__main;
    color: #333;
}

.aft .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.aft .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date{
    box-shadow: inset 0 0 0 1px #bebebe, inset 0 0 0 1000px rgba($aft-color__main, 0.6);
}

.center-align{
    text-align: center !important;
}
