﻿/*********************************************************************
**************************  LOGIN STYLESHEET  ************************
*********************************************************************/
@import '../variables_custom.scss';

.satelicom-login__button {
    margin-top: 15px;
    color: $satelicom-login-button__color;
    background-color: $satelicom-color__main;
    border-color: $satelicom-color__main;
    font-weight: bold;
}

.satelicom-login {
    background-image: $satelicom-login__background;
    background-position: 100% 0;
    -webkit-background-size: cover;
    background-size: cover;
    overflow-y: auto;
    height: 100vh;
    width: 100vw;
}

footer {
    color: white;
}

.satelicom-login__title {
    color: white;
    font-weight: 300;
}

.satelicom-login__button:hover,
.satelicom-login__button:active,
.satelicom-login__button:focus,
.satelicom-login__button:hover:active {
    color: $satelicom-login-button__color !important;
    background-color: $satelicom-color__main !important;
    border-color: $satelicom-login-button__color !important;
}



.satelicom-login__container-wrapper {
    background: none;
    width: 600px;
}

.satelicom-login__reset-pwd-cursor {
    .dx-slider-handle {
        background-color: $satelicom-color__main !important;
    }

    .dx-slider-range-visible {
        border-color: $satelicom-color__main !important;
    }
}

::ng-deep dx-slider.satelicom .dx-slider-handle {
    background-color: $satelicom-color__main !important;
}

::ng-deep dx-slider.satelicom > div > div > div.dx-slider-range {
    border: 1px solid $satelicom-color__main !important;
    background: rgba($satelicom-color__main, 0.7) !important;
} 