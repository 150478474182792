/*********************************************************************
**********************  ATS CUSTOM STYLESHEET  *********************
*********************************************************************/

/*********************************************************************
*****************************  GENERIC  ******************************
*********************************************************************/

@import "../../variables.scss";

// Colors
$basecolor: $aft-color__main;

$satelicom-color__main: $basecolor;

$satelicom-login__text__color: $basecolor;

$satelicom-dx-grid__selected-row__background-color: rgba($basecolor, 0.2);

$satelicom-logo: url('../images/LogoCustomer.png');

$satelicom-logo-small: url('../images/LogoCustomer_black.png');

//Login
$satelicom-login__background: url('./images/login-background-2.jpg');

$satelicom-login-button__color: #FFF;

$satelicom-selected-text__color: #FFF;