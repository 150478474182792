/*********************************************************************
**************************  MAIN STYLESHEET  *************************
*********************************************************************/

// Override variables use $variable=xxx !default in BASE to override
@import "./variables_custom.scss";

//Override classes
//EXAMPLE to override base classes
@import "./scss/map.scss";
@import "./scss/sidenav.scss";
@import "./scss/filter.scss";
@import "./scss/preferences.scss";
@import "./scss/header.scss";
@import "./scss/login.scss";
@import "./scss/calendar.scss";