﻿/*********************************************************************
*************************  HEADER STYLESHEET  ************************
*********************************************************************/
@import '../variables_custom.scss';
@import '../../../variables.scss';

.wortach-header__logo--login {
    background: $wortach-logo no-repeat;
    height: 70px;
}

.wortach-header__logo__wrapper {
    float: left;
    margin-top: 5px;
    margin-left: 43px;
}

.wortach-header__logo {
    background: $wortach-logo-small no-repeat;
    background-size: contain;
    height: 37px;
    width: 170px;
    margin: -5px 1px 0 0;
}

.wortach-header__dropdown-menu {
    &.dropdown-menu {
        > li {
            > a,
            > a:focus,
            > a:active,
            > a:hover {
                color: $aft-header__dropdown-menu__item__color;
                font-weight: $aft-header__dropdown-menu__item__font-weight;
            }
        }
        > li:active {
            background-color: rgba($wortach-color__main, 0.5);
        }

        @media (max-width: 767px) {
            left: auto;
            right: 0;
            position: absolute;
            margin: 4px 0 0;
        }
    }
}

.aft-header__navbar {
    background-color: $aft-navbar__background-color;
    box-shadow: $aft-navbar__box-shadow;
    height: $aft-header__navbar__height;
    margin: 0;

    .navbar-nav {
        margin: 0 -15px;

        > li,
        > .open {
            > a,
            > a:focus,
            > a:active,
            > a:hover {
                color: $aft-header__dropdown-menu__item__color;
                background-color: $aft-navbar__background-color;
                font-weight: $aft-header__dropdown-menu__item__font-weight;
            }
        }

        .aft-header__dropdown-menu {
            &.dropdown-menu {
                > li {
                    > a,
                    > a:focus,
                    > a:active,
                    > a:hover {
                        color: $aft-header__dropdown-menu__item__color;
                        font-weight: $aft-header__dropdown-menu__item__font-weight;
                    }
                }
                > li:active {
                    background-color: #008c956b;
                }

                @media (max-width: 767px) {
                    left: auto;
                    right: 0;
                    position: absolute;
                    margin: 4px 0 0;
                }
            }
        }
    }
}

.wortach .help-btn{
    background-color: rgba($wortach-color__main, 0.7)!important;
}
  
.wortach  .help-btn:hover{
    color: #3e3f3a !important
}