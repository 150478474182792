@import "../variables_custom.scss";

::ng-deep #ngwizard.satelicom>ul>li>a {
  border-radius: 0% !important;
  color: grey !important;
  background: rgba($satelicom-color__main, 0.5) !important;
}


#applyBtn.satelicom,
::ng-deep #ngwizard.satelicom>div.btn-toolbar>div.btn-group>button {
  background-color: rgba($satelicom-color__main, 0.3);
  border: 2px solid $satelicom-color__main;
}

#applyBtn.satelicom:hover,
::ng-deep #ngwizard.satelicom>div.btn-toolbar>div.btn-group>button:hover {
  background-color: rgba($satelicom-color__main, 0.7);
}

/* Customers Tree Overrides */

::ng-deep .satelicom-main-bgcolor {
  background-color: $satelicom-color__main;
}

::ng-deep .satelicom .dx-treeview-item:hover {
  background-color: rgba($satelicom-color__main, 0.2) !important;
}

::ng-deep .satelicom .dx-treeview-item-without-checkbox.dx-state-focused>.dx-treeview-item {
  background-color: $satelicom-color__main !important;
}

::ng-deep .satelicom .dx-list-item:hover {
  background-color: rgba($satelicom-color__main, 0.2) !important;
}
::ng-deep .satelicom .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
  background-color: rgba($satelicom-color__main, 0.2) !important;
  color: #333;
}

.satelicom .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.satelicom .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date{
    box-shadow: inset 0 0 0 1px #bebebe, inset 0 0 0 1000px rgba($satelicom-color__main, 0.6);
}